<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.list' }">
                {{ this.$t('menu.company_dashboard.assignments') }}
              </router-link>
            </li>
            <li>{{ this.$t('general.edit_assignment') }}</li>
          </ul>
        </nav>
      </div>
      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>{{ this.$t('general.edit_assignment') }}</span></h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <ul id="main-switcher" class="uk-child-width-expand uk-tab stepper-buttons"
            uk-switcher="connect: #main-switcher-tabs ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class="p-4" href="#"><i class="icon-feather-flag"></i> <b>{{ this.$t('messages.login') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#"><i class="uil-book-open"></i> <b>{{ this.$t('messages.content') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#"><i class="uil-users-alt"></i> <b>{{ this.$t('users.users') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#"><i class="uil-gift"></i> <b>{{ this.$t('general.gift') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#" @click="hideSomeInputsForClassRoomEducation"><i class="uil-cog"></i>
              <b>{{ this.$t('general.settings') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#" @click="decideQrCodeForShowing"><i class="uil-invoice"></i>
              <b>{{ this.$t('general.result') }}</b></a>
          </li>
        </ul>

        <default-loading
            v-if="(assignment_loading || !formattedAssignmentItem) && defaultLoadingModal"></default-loading>
        <template v-else>
          <div v-if="item_loaded" class="card-body">
            <ul id="main-switcher-tabs" class="uk-switcher uk-margin">
              <!--tab: özellikler-->
              <li>
                <div class="row">
                  <div class="col-xl-9 m-auto">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="course_title">{{
                          this.$t('general.assignment_name')
                        }}<span class="required">*</span></label>
                      <div class="col-md-9">
                        <input id="course_title" v-model="name" class="form-control" required="" type="text"/>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="short_description">{{
                          this.$t('general.description')
                        }}<span class="required">*</span><i class="uil-info-circle ml-1"
                                                            uk-tooltip="title: Açıklama en fazla 1000 harf olabilir."></i>
                        <br>({{ textarea_length }} /
                        1000)
                      </label>
                      <div class="col-md-9">
                        <textarea id="short_description" v-model="description" class="form-control" maxlength="1000"
                                  name="short_description"></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="logo">{{ this.$t('general.cover_image') }}<span
                          class="required">*</span></label>
                      <div class="col-md-9">
                        <div class="
                          position-relative
                          d-flex
                          justify-center justify-content-center
                        ">
                          <img :src="cover_image_link
                            ? cover_image_link
                            : '@/assets/images/no-photo.png'
                            " alt="" class="rounded img-fluid logoarea" style="height: 190px"/>
                          <b>ddd</b>
                          <input id="cover_photo_input" accept="image/jpeg, image/jpg, image/png, image/gif"
                                 class="d-none"
                                 type="file"
                                 @change="processFile($event)"/>
                          <button class="btn btn-default btn-icon-label" style="top: 50%; position: absolute"
                                  type="button"
                                  @click="inputClick()">
                            <span class="btn-inner--icon">
                              <i class="uil-image"></i>
                            </span>
                            <span class="btn-inner--text">{{
                                $t("general.image_change")
                              }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(1)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>
              <!--tab: eğitim-->
              <li>
                <div class="row">
                  <div class="col-md-12">
                    <div id="modal_learning" class="uk-modal-full uk-modal" uk-modal="">

                      <div class="uk-modal-footer bggray">
                        <h3 class="float-left">
                          <i class="uil-presentation-plus"></i> {{ $t('general.select_content') }}
                        </h3>
                        <button class="
                          uk-button uk-button-default
                          small
                          uk-modal-close
                          float-right
                        " type="button">
                          <i class="uil-times"></i> {{ $t("general.close") }}
                        </button>
                      </div>

                      <div class="uk-modal-dialog bggray h-100">
                        <Content :selected_items="all_selected_educations"
                                 :show_program="true" @addNewEducation="addContentToAssignment($event)"
                                 @removeEducation="removeContentFromAssignment($event)"></Content>
                      </div>
                    </div>

                    <div class="mb-2 mx-2 bggray px-2 pt-2 pb-3 card">
                      <div class="row text-right">
                        <div class="col-md-10">
                          <div class="row" style="display:flex;justify-content:end">
                            <label class="col-md-3 col-form-label">
                              Sıralı içerik olsun
                            </label>
                            <div class="col-md-1">
                              <label class="uk-switch" for="can_open_contents_in_order">
                                <input id="can_open_contents_in_order" v-model="can_open_contents_in_order"
                                       type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <button class="btn btn-info btn-sm mb-3" uk-toggle="target: #modal_learning">
                            <i class="uil-plus m-0"></i> {{ $t('general.select_content') }}
                            <span class="required">*</span>
                          </button>
                        </div>
                      </div>
                      <div v-if="educations.length == 0" class="text-center mb-3">İçerik seçilmedi</div>
                      <draggable v-else :list="educations" class="uk-accordion-content mt-0" group="education_widget">
                        <div v-for="(education, index) in educations" :key="'assignment_content_list_' + index">
                          <div class="sec-list-item">
                            <div>
                              <i class="uil-list-ul mr-2"></i>
                              <p>{{ education.title }}</p>
                              <label class="mb-0 mx-2" style="
                                      font-size: 0.75rem;
                                      background: #3e416d;
                                      padding: 1px 5px;
                                      border-radius: 3px;
                                      color: white;
                                    ">{{ $t(education.name) }}</label>
                            </div>
                          </div>
                        </div>
                      </draggable>
                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(0)">
                        <i class="icon-feather-arrow-left"></i>{{ $t('general.previous') }}
                      </button>
                      <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(2)">
                        <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <!--tab: kullanıcılar-->
              <li>
                <UserGroupSelection :added_users="added_users" :assignment_id="this.$route.params.id"
                                    :show_only_unassigned_users="show_only_unassigned_users"
                                    @setCheckedGroups="($event) => { checked_groups = $event}"
                                    @setCheckedUsers="($event) => {checked_users = $event} ">
                </UserGroupSelection>

                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(1)">
                    <i class="icon-feather-arrow-left"></i> {{ $t('general.previous') }}
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(3)">

                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>

              <!--tab: hediye-->
              <li>
                <div class="row">
                  <div class="col-md-3">
                    <div id="gift-mode" class="btn-night-mode">
                      <i class="icon-feather-gift"></i> {{ this.$t('general.give_a_gift_at_the_end') }}
                      <span class="btn-night-mode-switch">
                        <label class="uk-switch" for="default-1">
                          <input id="default-1" v-model="assignment_item.has_present" type="checkbox"/>
                          <div class="uk-switch-slider"></div>
                        </label>
                      </span>
                    </div>
                    <div v-show="assignment_item.has_present" class="form-group row mt-3">
                      <div class="col-md-12">
                        <label class="col-form-label" for="course_title">
                          Hediye Verme Kuralı<span class="required">*</span></label>
                        <select v-model="gift_rule_id" class="selectpicker7">
                          <option value="1">En Kısa Zamanda Tamamlayanlar</option>
                          <option value="2">En Hızlı Tamamlayanlar</option>
                          <option value="3">
                            Bitiren İlk X Sayıda Kullanıcı
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div v-if="assignment_item.has_present" class="col-md-9">
                    <div class="uk-accordion-content mt-0">
                      <div id="modal_gifts" class="uk-modal-full uk-modal" uk-modal="">
                        <div class="uk-modal-footer bggray">
                          <h3 class="float-left">
                            <i class="uil-presentation-plus"></i>
                            {{ $t("gift.management") }}
                          </h3>
                          <button class="
                            uk-button uk-button-default
                            small
                            uk-modal-close
                            float-right
                          " type="button">
                            <i class="uil-times"></i> {{ $t("general.close") }}
                          </button>
                        </div>

                        <div class="uk-modal-dialog bggray h-100">
                          <Gift :selected_items="all_selected_gifts" @addNewGift="addGiftToContent($event)"></Gift>
                        </div>
                      </div>
                      <ul class="sec-list gift">
                        <button class="btn btn-secondary mb-3 w-100" uk-toggle="target: #modal_gifts">
                          <i class="uil-plus m-0"></i> Hediye Ekle<span class="required">*</span>
                        </button>
                        <draggable :list="gifts" group="gifts_widget">
                          <li v-for="(item, index) in gifts">
                            <div class="sec-list-item" style="cursor: move">
                              <div>
                                <img v-if="item.image" :src="item.image" alt=""/>
                                <img v-if="item.image_link" :src="item.image_link" alt=""/>
                                <p>{{ item.name }}</p>
                                <span style="
                                  display: block;
                                  font-size: 0.875rem;
                                  color: #ae71ff;
                                  margin-left: 0.4rem;
                                ">(<i class="uil-user d-inline"></i>
                                  {{ index + 1 }}. kazanan)</span>
                              </div>
                              <div>
                                <div class="btn-act">
                                  <a href="#" @click.prevent="
                                    removeGiftFromContent(item.id)
                                    "><i class="icon-feather-x"></i></a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </draggable>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0 pr-0">
                  <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(2)">
                    <i class="icon-feather-arrow-left"></i> {{ $t('general.previous') }}
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(4)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>

              <!--tab: ayarlar-->
              <li>
                <div class="container">
                  <div class="row">
                    <div class="col-md-3">
                      <ul class="uk-tab-right uk-tab sidemenu"
                          uk-switcher="connect: #settings-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                        <li class="uk-active">
                          <a href="#"><i class="uil-keyboard"></i> <b>Atama Ayarları</b></a>
                        </li>
                        <li>
                          <a href="#"><i class="uil-envelope-edit"></i>
                            <b>Mesaj Ayarları</b></a>
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-9">
                      <ul id="settings-tab" class="uk-switcher">
                        <li class="uk-active">
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="course_title">
                              {{ this.$t('general.program_option') }}<span class="required">*</span></label>
                            <div class="col-md-6">
                              <select v-model="is_compulsory" class="selectpicker7">
                                <option value="1">Zorunlu</option>
                                <option value="0">Zorunlu Değil</option>
                              </select>
                            </div>
                          </div>
                          <hr/>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">
                              {{ this.$t('general.feature_the_training') }}
                              <i class="uil-info-circle" uk-tooltip="title: Eğitimi öne çıkanlar listesine ekler"></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="default-one-cikanlar">
                                <input id="default-one-cikanlar" v-model="highlight_education" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                          <hr/>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">{{
                                this.$t('general.repetition')
                              }}
                              <i class="uil-info-circle"
                                 uk-tooltip="title: Kullanıcı içerikleri tamamladığında tekrar edebilmesi için bu seçenek seçilir"></i></label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="repeatable">
                                <input id="repeatable" v-model="repeatable" disabled type="checkbox"/>
                                <div class="uk-switch-slider" style="cursor:not-allowed"></div>
                              </label>
                            </div>
                          </div>
                          <div v-if="repeatable" class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">Tekrar Sayısı<span
                                class="required">*</span>
                              <i class="uil-info-circle"></i></label>
                            <div class="col-md-6">
                              <input :value="repetition_no" class="form-control" disabled min="0" style="width: 100px"
                                     type="number" @keypress="isNumber($event)"/>
                            </div>
                          </div>

                          <div class="form-group row mb-2 input-checkbox">
                            <div class="col-md-12">
                              <label class="col-form-label" for="start_date">{{ $t('general.start_day') }}<span
                                  class="required">*</span>
                                <i class="uil-info-circle" uk-tooltip="title: Eğitim Başlangıç Tarihi"></i></label>
                              <input id="start_date" v-model="start_date" :max="end_date" :min="setMinimumDate()"
                                     class="form-control" type="date"/>

                              <label class="col-form-label" for="end_date">Son Tamamlama Tarihi<span
                                  class="required">*</span>
                                <i class="uil-info-circle"
                                   uk-tooltip="title:Bitiş tarihi eğitimin süresinin dolduğu tarihtir.O gün izlenme yapılamaz. "></i></label>
                              <input id="end_date" v-model="end_date"
                                     :min="start_date ? new Date(new Date(start_date).setDate(new Date(start_date).getDate() + 1)).toISOString().split('T')[0] : ''"
                                     class="form-control"
                                     type="date"/>
                            </div>
                          </div>

                          <hr/>
                          <div class="form-group domain row mb-2">
                            <label class="col-md-6 col-form-label" for="assigment_duration">Süre <i
                                class="uil-info-circle"></i></label>
                            <div class="col-md-6">
                              <div class="d-flex">
                                <input id="assigment_duration" v-model="duration" class="form-control flex-grow-1"
                                       min="0"
                                       type="number" @keypress="isNumber($event)"/><span>{{
                                  $t("general.minutes")
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="survey_required" style="padding-right:0">{{
                                this.$t('general.add_survey_after_the_training')
                              }}
                              <i class="uil-info-circle"
                                 uk-tooltip="title: Eğitim sonunda kullanıcıların eğitimi puanlamaları için gerekli olan ankettir."></i></label>
                            <div class="col-md-6">

                              <label class="uk-switch" for="survey_required">
                                <input id="survey_required" v-model="survey_required" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                          <div v-show="survey_required" class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">Değerlendirme anketi
                              seçiniz.<span class="required">*</span></label>
                            <div class="col-md-6">
                              <select v-model="survey_id" class="selectpicker_survey">
                                <option value="">
                                  {{ $t("general.select_option") }}
                                </option>
                                <option v-for="(item, index) in survey_items" :value="item.id">
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <hr/>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">{{
                                this.$t('general.show_the_top_rankings_list')
                              }}
                              <i class="uil-info-circle"
                                 uk-tooltip="title: Eğitimi en performanslı şekilde tamamlayan kullanıcıların listesidir. Eğitimin atandığı tüm kullanıcılar görebilir."></i></label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="appear_top">
                                <input id="appear_top" v-model="appear_top" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="has_feedbacks">
                              {{ this.$t('general.experience_sharing') }}
                              <i class="uil-info-circle" uk-tooltip="title: Deneyim Paylaşımları."></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="has_feedbacks">
                                <input id="has_feedbacks" v-model="has_feedbacks" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="can_be_watched_again">
                              {{ this.$t('general.allow_users_to_watch_the_training_again') }}
                              <i class="uil-info-circle"
                                 uk-tooltip="title: Eğitimini tamamlamış ve süresi geçmiş bir eğitimi kullanıcı tekrar izleyebilsin mi?"></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="can_be_watched_again">
                                <input id="can_be_watched_again" v-model="can_be_watched_again" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="send_mail_notification">E-Posta
                              Gönderilsin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="send_mail_notification">
                                <input id="send_mail_notification" v-model="send_mail_notification" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                            <div v-if="send_mail_notification" class="col-md-12">
                              <p><input id="notification1" v-model="isRemindEntered" class="mr-2" name="notification1"
                                        type="checkbox">Eğitime
                                <input v-model="remind_if_not_entered_x_days" class="d-inline" max="365" min="1"
                                       name="notification1_day1" style="width:65px;height:35px;" type="number"
                                       value="1"> gün giriş yapılmadıysa her
                                <input v-model="remind_if_not_entered_every_y_days" class="d-inline" max="365"
                                       min="1" name="notification1_day2" style="width:65px;height:35px;" type="number"
                                       value="1"> günde bir mesaj gönder.
                              </p>
                              <p>
                                <input id="notification2" v-model="isRemindStarting" class="mr-2" name="notification2"
                                       type="checkbox">Eğitim başlama tarihinden
                                <input v-model="remind_before_starting_x_days" class="d-inline" max="365" min="1"
                                       name="notification2_day1" style="width:65px;height:35px;" type="number"
                                       value="1"> gün önce her
                                <input v-model="remind_before_starting_every_y_days" class="d-inline" max="365"
                                       min="1" name="notification2_day2" style="width:65px;height:35px;" type="number"
                                       value="1"> günde bir mesaj gönder.
                              </p>
                              <p><input id="notification3" v-model="isRemindEnding" class="mr-2" name="notification3"
                                        type="checkbox">Eğitimin bitiş tarihinden
                                <input v-model="remind_before_ending_x_days" class="d-inline" max="365" min="1"
                                       name="notification2_day1" style="width:65px;height:35px;" type="number"
                                       value="1"> gün önce her
                                <input v-model="remind_before_ending_every_y_days" class="d-inline" max="365"
                                       min="1" name="notification2_day2" style="width:65px;height:35px;" type="number"
                                       value="1"> günde bir mesaj gönder.
                              </p>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="send_mobile_notification">Mobil Bildirim
                              Gönderilsin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="send_mobile_notification">
                                <input id="send_mobile_notification" v-model="send_mobile_notification"
                                       type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>

                          </div>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="can_be_added_to_calendar">Takvim'e
                              Eklensin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="can_be_added_to_calendar">
                                <input id="can_be_added_to_calendar" v-model="can_be_added_to_calendar"
                                       type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="has_customized_template">E-posta Şablonunu
                              Özelleştir</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="has_customized_template">
                                <input id="has_customized_template" v-model="has_customized_template" type="checkbox"/>
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="row">
                            <div v-if="has_customized_template" class="col-6">
                              <button id="uk_button_mail_codes" class="
                              btn btn-secondary
                              uk-button uk-button-default
                              mb-3
                            " type="button">
                                <i class="uil-plus-circle"></i> Kod Bloğu Ekle
                              </button>
                            </div>

                            <div class="uk-width-large" uk-dropdown="toggle:#uk_button_mail_codes">
                              <div class="uk-dropdown-grid uk-child-width-1-2@m" uk-grid>
                                <div>
                                  <ul class="uk-nav uk-dropdown-nav">
                                    <template v-for="(code, i) in mail_template_codes">
                                      <template v-if="i < codeSlice()">
                                        <li class="uk-active">
                                          <a href="#">{{ $t(code.key_name) }}</a>
                                        </li>
                                        <li>
                                          <b>{{ code.code }}</b>
                                          <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                            {{ $t("general.copy") }}</a>
                                        </li>
                                        <li class="uk-nav-divider"></li>
                                      </template>
                                    </template>
                                  </ul>
                                </div>
                                <div>
                                  <ul class="uk-nav uk-dropdown-nav">
                                    <template v-for="(code, i) in mail_template_codes">
                                      <template v-if="i >= codeSlice()">
                                        <li class="uk-active">
                                          <a href="#">{{ code.name }}</a>
                                        </li>
                                        <li>
                                          <b>{{ code.code }}</b>
                                          <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                            {{ $t("general.copy") }}</a>
                                        </li>
                                        <li class="uk-nav-divider"></li>
                                      </template>
                                    </template>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <html-area v-if="mail_template.html != undefined && has_customized_template == true"
                                       :company="company" :model="mail_template"
                                       @update="updateMailTemplate"></html-area>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="uk-card-footer float-right pb-0 pr-0 pr-0">
                    <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(3)">
                      <i class="icon-feather-arrow-left"></i> {{ $t('general.previous') }}
                    </button>
                    <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(5)">
                      <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                    </button>
                  </div>
                </div>
              </li>

              <!--tab: sonuç-->
              <li>
                <div class="row">
                  <div class="col-12 my-lg-5">
                    <div class="text-center">
                      <!-- <h2 class="mt-0">
                        <i class="icon-feather-check-circle text-success"></i>
                      </h2>
                      <h3 class="mt-0">Atama Hazır !</h3> -->

                      <div class="col-md-6 offset-md-3">
                        <table class="uk-table uk-table-divider result-table">
                          <tbody>
                          <tr>
                            <td class="text-center" colspan="2">
                              <img :src="cover_image_link
                                  ? cover_image_link
                                  : '@/assets/images/no-photo.png'
                                  " alt="" class="logoarea rounded img-fluid"/>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ this.$t('general.assignment_name') }}:</td>
                            <td class="text-left">
                              <b>{{ name }}</b>
                            </td>
                          </tr>
                          <!-- <tr>
                          <td class="text-right">{{ $t('general.training_name') }}:</td>
                          <td class="text-left">
                            <b>İş Sağlığı ve Güvenliği</b>
                          </td>
                        </tr> -->
                          <tr v-if="checked_users.length > 0">
                            <td class="text-right">{{ this.$t('general.number_of_assigned_users') }}:</td>
                            <td class="text-left">
                              <b>{{ checked_users.length }}</b>
                            </td>
                          </tr>
                          <tr v-if="checked_groups.length > 0">
                            <td class="text-right">Atanan Grup Sayısı:</td>
                            <td class="text-left">
                              <b>{{ checked_groups.length }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ this.$t('general.give_a_gift_at_the_end') }}:</td>
                            <td class="text-left">
                              <b>{{
                                  assignment_item.has_present
                                      ? $t("general.yes")
                                      : $t("general.no")
                                }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">
                              {{ this.$t('general.top_rankings_list_display') }}:
                            </td>
                            <td class="text-left">
                              <b>{{ appear_top ? $t("general.yes") : $t("general.no") }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ this.$t('general.start_day') }}:</td>
                            <td class="text-left">
                              <b>{{ start_date }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">Son Tamamlama Tarihi:</td>
                            <td class="text-left">
                              <b>{{ end_date }}</b>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <div v-if="qrCodeCanBeShown">
                          <QrCodeModal :hideSubmit="true"
                                       :modal_name="'qr_code_for_classroom_education'"
                                       :modal_title="qor_code_modal_title" :qr_text="qr_text"
                                       :showCloseBtn="false"></QrCodeModal>
                        </div>

                      </div>

                      <!-- <p class="w-75 mb-2 mx-auto" style="font-size: 0.875rem">
                        Kaydet butonuna tıklayarak<br />tüm kullanıcılara atamayı
                        gerçekleştirebilirsiniz.
                      </p> -->
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(4)">
                    <i class="icon-feather-arrow-left"></i> {{ $t('general.previous') }}
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                    <i class="icon-feather-arrow-right"></i> {{ $t("general.save") }}

                  </button>
                </div>
              </li>
            </ul>
          </div>

        </template>
      </div>
    </div>
    <modal :adaptive="false" height="auto" name="qr_code_for_classroom_education" width="700">
      <QrCodeModal :hideSubmit="true" :modal_name="'qr_code_for_classroom_education'"
                   :modal_title="qor_code_modal_title"
                   :qr_text="qr_text"></QrCodeModal>
    </modal>
    <div id="modal_customize" class="uk-flex-center" style="z-index: 9999999;" uk-modal>
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="row" style="margin-top:1rem;margin-bottom: 1rem;margin-right: 1rem;">
          <div class="col-md-3" style="padding-left: 20px;">
            <ul class="uk-tab-right uk-tab sidemenu"
                uk-switcher="connect: #contentSetting ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li class="uk-active">
                <a href="#"><i class="uil-keyboard"></i>
                  <b>İçerik Ayarları</b></a>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <ul id="contentSetting" class="uk-switcher">
              <li class="uk-active">
                <div class="row">
                  <div class="col-md-12" style="padding:0">
                    <label class="uk-form-label">
                      İçerik Özelliği
                    </label>
                    <select v-model="selectedContentFeature" class="form-control">

                      <option v-for="contentFeature in content_features" :value="contentFeature.type">
                        {{ contentFeature.name }}
                      </option>
                    </select>
                  </div>
                  <template v-if="selectedContentFeature === 'timed_content'">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="pass">
                          Başlangıç Tarihi
                          <span class="required">*</span>
                        </label>
                        <VueCtkDateTimePicker v-model="contentsTimed[selectedEducationId].starts_at"
                                              :format="'YYYY-MM-DD HH:mm:ss'"
                                              :max-date="contentsTimed[selectedEducationId].ends_at"
                                              :min-date="setMinimumDate()"/>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="selectedContentFeature === 'timed_content'" class="row">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="pass">
                        Bitiş Tarihi
                        <span class="required">*</span>
                      </label>
                      <VueCtkDateTimePicker v-model="contentsTimed[selectedEducationId].ends_at"
                                            :format="'YYYY-MM-DD HH:mm:ss'"
                                            :min-date="contentsTimed[selectedEducationId].starts_at"
                                            :no-button-now="true"/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div v-if="selectedContentFeature === 'timed_content'" class="row" style="float:right">
              <button class="uk-button uk-button-outline small mr-2" type="button">
                <i class="icon-feather-arrow-left"></i>Vazgeç
              </button>
              <button :disabled="!controlContentRequired" class="uk-button uk-button-primary small" type="button"
                      @click="saveContentFeatures()">
                <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
import Content from "@//view/components/assignment/Content";
import Gift from "@//view/components/assignment/Gift";
import HtmlArea from "@/view/company/Mail/Html";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import assignment_module, {
  BASE_URL as ASSIGNMENT_BASE_URL,
  ERROR as ASSIGNMENT_ERROR,
  GET_ITEM as ASSIGNMENT_GET_ITEM,
  ITEM as ASSIGNMENT_ITEM,
  LOADING as ASSIGNMENT_LOADING,
  MODULE_NAME as ASSIGNMENT_MODULE_NAME,
  UPDATE_ITEM as ASSIGNMENT_UPDATE_ITEM,
} from "@/core/services/store/assignment.module";

import survey_module, {
  BASE_URL as SURVEY_BASE_URL,
  GET_ITEMS as SURVEY_GET_ITEMS,
  ITEMS as SURVEY_ITEMS,
  MODULE_NAME as SURVEY_MODULE_NAME,
} from "@/core/services/store/evaluation_survey.module";

import {GET_ITEM_DETAIL_BY_ID,} from "@/core/services/store/REST.module";
import QrCodeModal from "@/view/components/assignment/QrCodeModal.vue";

const _SURVEY_MODULE = SURVEY_MODULE_NAME;
const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE_NAME;

export default {
  name: "AddAssignment",
  components: {
    UserGroupSelection,
    draggable,
    Content,
    Gift,
    HtmlArea,
    DefaultLoading,
    QrCodeModal,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_SURVEY_MODULE, survey_module);
    registerStoreModule(_ASSIGNMENT_MODULE, assignment_module);
  },
  data() {
    return {
      selectedContentFeature: 'untimed_content',
      content_features: [{
        name: 'Süreli İçerik', type: 'timed_content', starts_at: 'null', ends_at: 'null'
      }, {
        name: 'Süresiz İçerik', type: 'untimed_content'
      }],
      contentsTimed: [],
      selectedEducationId: null,
      lastEndDate: null,
      lastStartDate: null,
      can_open_contents_in_order: true,
      isClassRoomEducationSelected: false,
      qrCodeCanBeShown: false,
      qr_text: null,
      qor_code_modal_title: "Sınıf İçi Eğitim QR Kodu",
      textarea_length: 0,
      restUrl: "api/mail-template/assignment",
      successMessage: null,
      errorMessages: [],
      cover_image: null,
      cover_image_link: null,
      checked_users: [],
      checked_groups: [],
      added_users: [],
      added_groups: [],
      name: null,
      is_compulsory: 0,
      start_date: null,
      end_date: null,
      repeatable: true,
      repetition_no: 0,
      duration: 0,
      survey_id: null,
      appear_top: false,
      survey_required: true,
      highlight_education: false,
      has_feedbacks: false,
      description: null,
      gift_rule_id: 1,
      active_content_id: null,
      gifts: [],
      educations: [],
      has_customized_template: false,
      send_mail_notification: false,
      send_mobile_notification: false,
      can_be_added_to_calendar: false,
      can_be_watched_again: false,
      mail_template: [],
      mail_template_codes: [],
      company: [],
      all_selected_educations: [],
      all_selected_gifts: [],
      item_loaded: false,
      remind_if_not_entered_x_days: 1,
      remind_if_not_entered_every_y_days: 1,
      remind_before_starting_x_days: 1,
      remind_before_starting_every_y_days: 1,
      remind_before_ending_x_days: 1,
      remind_before_ending_every_y_days: 1,
      isRemindEntered: false,
      isRemindStarting: false,
      isRemindEnding: false,
      show_only_unassigned_users: true,
      defaultLoadingModal: true

    };
  },
  computed: {
    controlContentRequired() {
      if (!this.contentsTimed[this.selectedEducationId].starts_at || !this.contentsTimed[this.selectedEducationId].ends_at)
        return false
      return true
    },
    assignment_item: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ITEM];
      },
      set(value) {
      },
    },
    assignment_loading: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_LOADING];
      },
      set(value) {
      },
    },
    formattedAssignmentItem() {
      let self = this;
      let item = self.assignment_item;

      self.item_loaded = false;
      if (item == null || item.users == undefined) return false;
      self.textarea_length = item.description.length
      // self.checked_users = item.users;
      self.added_users = item.users;
      self.name = item.name;
      self.description = item.description;
      self.is_compulsory = item.is_compulsory;
      self.start_date = item.start_date;
      self.end_date = item.end_date;
      self.repeatable = item.repeatable;
      self.repeatable = !!item.order_number;
      self.duration = item.duration;
      self.repetition_no = item.repetition_no;
      self.survey_id = item.evaluation_survey_id;
      self.appear_top = item.appear_top;

      (item.send_mail_notification || item.send_mail_notification === 1) ? self.send_mail_notification = true : self.send_mail_notification = false;
      (item.send_mobile_notification || item.send_mobile_notification === 1) ? self.send_mobile_notification = true : self.send_mobile_notification = false;

      (item.can_be_added_to_calendar || item.can_be_added_to_calendar === 1) ? self.can_be_added_to_calendar = true : self.can_be_added_to_calendar = false;
      (item.can_be_watched_again || item.can_be_watched_again === 1) ? self.can_be_watched_again = true : self.can_be_watched_again = false;
      (item.has_customized_template || item.has_customized_template === 1) ? self.has_customized_template = true : self.has_customized_template = false;
      if (item.has_customized_template && self.mail_template.html != undefined)
        self.mail_template.html = item.mail_template;
      self.has_feedbacks = item.has_feedbacks == true ? true : false;
      self.survey_required = item.survey_required;
      self.highlight_education = item.highlight_education;
      self.can_open_contents_in_order = item.can_open_contents_in_order;
      self.gift_rule_id = item.gift_rule_id;
      self.gifts = item.gifts;
      self.educations = item.educations;
      self.all_selected_educations = item.all_selected_educations;
      self.all_selected_gifts = item.all_selected_gifts;
      self.cover_image_link = item.cover_image_link;
      self.item_loaded = true;

      console.log("self", self)
      if (item.remind_if_not_entered_x_days) {
        this.isRemindEntered = true;
        this.remind_if_not_entered_x_days = item.remind_if_not_entered_x_days;
        this.remind_if_not_entered_every_y_days = item.remind_if_not_entered_every_y_days;
      }
      if (item.remind_before_starting_x_days) {
        this.isRemindStarting = true;
        this.remind_before_starting_x_days = item.remind_before_starting_x_days;
        this.remind_before_starting_every_y_days = item.remind_before_starting_every_y_days;
      }
      if (item.remind_before_ending_x_days) {
        this.isRemindEnding = true;
        this.remind_before_ending_x_days = item.remind_before_ending_x_days;
        this.remind_before_ending_every_y_days = item.remind_before_ending_every_y_days;

      }
      return true;
    },
    survey_items: {
      get() {
        return store.getters[SURVEY_MODULE_NAME + "/" + SURVEY_ITEMS];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    setMinMaxTime() {
      if (!this.lastEndDate) {
        this.lastEndDate = new Date();
      }
      if (!this.lastStartDate) {
        this.lastStartDate = new Date();
      }
      this.contentsTimed.forEach((content) => {
        if (this.lastEndDate.getTime() < new Date(content.ends_at).getTime()) {
          this.lastEndDate = new Date(content.ends_at);
        }
        if (this.lastStartDate.getTime() > new Date(content.starts_at).getTime())
          this.lastStartDate = new Date(content.starts_at);
      })
    },
    saveContentFeatures() {
      if (this.selectedContentFeature === 'timed_content') {
        this.setMinMaxTime();
      }
      this.contentsTimed[this.selectedEducationId].type = this.selectedContentFeature;
      this.selectedContentFeature === 'untimed_content';
      UIkit.modal("#modal_customize").hide();
    },
    showCustomize(id, index) {
      this.selectedEducationId = index;
      if (!this.contentsTimed[index]) {
        this.contentsTimed.push({
          id: id,
          type: 'untimed_content',
          starts_at: null,
          ends_at: null
        });
      } else {
        this.selectedContentFeature = this.contentsTimed[index].type
      }
      UIkit.modal("#modal_customize").show();
    },
    decideQrCodeForShowing: function () {

      const containsAnyClassRoomEducation = this.educations.some((education) => education.name === 'documents.classroom_education');
      if (containsAnyClassRoomEducation) {
        this.qrCodeCanBeShown = true;

        return;
      }

      this.qrCodeCanBeShown = false;
    },
    hideSomeInputsForClassRoomEducation: function () {
      // @todo: move central place
      this.isClassRoomEducationSelected = false;

      let isClassRoomEducationSelected = false;
      let repeatable = true;

      // const containsAnyClassRoomEducation = this.educations.some((education) => education.name === 'documents.classroom_education');
      // if (containsAnyClassRoomEducation) {
      //   isClassRoomEducationSelected = true;
      //   repeatable = false;
      // }
      // this.repeatable = repeatable;
      // this.isClassRoomEducationSelected = isClassRoomEducationSelected;
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode === 46) {
        evt.preventDefault();
        ;
      } else {
        return true;
      }
    },
    getMailTemplateData() {
      let self = this
      self.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: self.restUrl,
            acceptPromise: false,
          })
          .then((result) => {
            if (result.status) {
              self.company = result.data.company
              self.mail_template = result.data.template
              self.mail_template_codes = result.data.codes
            }
          });
    },
    saveForm() {
      let self = this;
      if (!this.isValidFields("ALL")) return false;
      if (self.duration == "" || self.duration < 0) self.duration = 0;
      let formData = new FormData();
      if (this.contentsTimed?.length > 0) {
        for (let i = 0; i < this.contentsTimed?.length; i++) {
          formData.append("contents[" + i + "][id]",
              this.contentsTimed[i].id)
          formData.append("contents[" + i + "][starts_at]",
              this.contentsTimed[i].starts_at)
          formData.append("contents[" + i + "][ends_at]",
              this.contentsTimed[i].ends_at)
          formData.append("contents[" + i + "][type]",
              this.contentsTimed[i].type);
        }
      } else {
        self.educations.forEach((element, index) => {
          formData.append("contents[" + index + "]", element.id);
        });
      }
      formData.append("name", self.name);
      formData.append("description", self.description);
      formData.append(
          "has_present",
          self.assignment_item.has_present == true ? 1 : 0
      );
      formData.append("gift_rule_id", self.gift_rule_id);
      self.gifts.forEach((element) => {
        formData.append("gifts[]", element.id);
      });
      formData.append("users", self.checked_users);
      formData.append("is_user", self.checked_users.length > 0 ? 1 : 0);

      formData.append("is_group", self.checked_groups.length > 0 ? 1 : 0);
      self.checked_groups.forEach((element) => {
        formData.append("groups[]", element);
      });

      formData.append("is_compulsory", self.is_compulsory == true ? 1 : 0);
      formData.append("start_date", self.start_date);
      formData.append("end_date", self.end_date);

      formData.append(
          "highlight_education",
          self.highlight_education == true ? 1 : 0
      );
      formData.append(
          "can_open_contents_in_order",
          self.can_open_contents_in_order
      );
      formData.append("duration", self.duration);

      formData.append(
          "survey_required",
          self.survey_required == true ? 1 : 0
      );
      if (self.survey_required == true)
        formData.append("evaluation_survey_id", self.survey_id);
      formData.append("send_mail_notification", self.send_mail_notification == true ? 1 : 0);
      formData.append("send_mobile_notification", self.send_mobile_notification == true ? 1 : 0);
      formData.append("can_be_added_to_calendar", self.can_be_added_to_calendar == true ? 1 : 0);
      formData.append("can_be_watched_again", self.can_be_watched_again == true ? 1 : 0);
      formData.append("has_customized_template", self.has_customized_template == true ? 1 : 0);
      if (self.has_customized_template == true)
        formData.append("mail_template", self.mail_template.html);

      formData.append("has_feedbacks", self.has_feedbacks == true ? 1 : 0);
      formData.append("appear_top", self.appear_top == true ? 1 : 0);
      if (self.cover_image) formData.append("cover_image", self.cover_image);

      if (this.isRemindEntered) {
        console.log("isRemindEntered")
        formData.append("remind_if_not_entered_x_days", self.remind_if_not_entered_x_days);
        formData.append("remind_if_not_entered_every_y_days", self.remind_if_not_entered_every_y_days);
      } else {
        formData.append("remind_if_not_entered_x_days", null);
        formData.append("remind_if_not_entered_every_y_days", null);
      }

      if (this.isRemindStarting) {
        console.log("isRemindStarting")
        formData.append("remind_before_starting_x_days", self.remind_before_starting_x_days);
        formData.append("remind_before_starting_every_y_days", self.remind_before_starting_every_y_days);
      } else {
        formData.append("remind_before_starting_x_days", null);
        formData.append("remind_before_starting_every_y_days", null);
      }

      if (this.isRemindEnding) {
        console.log("isRemindEnding")
        formData.append("remind_before_ending_x_days", self.remind_before_ending_x_days);
        formData.append("remind_before_ending_every_y_days", self.remind_before_ending_every_y_days);
      } else {
        console.log("isRemindEnding")
        formData.append("remind_before_ending_x_days", null);
        formData.append("remind_before_ending_every_y_days", null);
      }

      store
          .dispatch(ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_UPDATE_ITEM, {
            url: ASSIGNMENT_BASE_URL + "/update/" + this.$route.params.id,
            contents: formData,
          })
          .then((result) => {

            self.scrollToTop();
            this.defaultLoadingModal = false;
            if (this.error) {
              this.errorMessages.push(this.error);
            }
            const containsClassRoomEducation = result.contents_programs.some(
                (education) => education.content_category_id === 3
            );
            if (containsClassRoomEducation) {
              self.qr_text = `${window.location.origin}/#/assignments/${this.assignment_item.id}/classroom-education`;
              this.$modal.show("qr_code_for_classroom_education");
              return;
            }
            if (!this.error) {
              this.successMessage = self.$t("general.successfully_updated");
              this.$router.push({name: "company.assignments.list"});
            } else this.errorMessages.push(this.error);
          });
    },
    updateMailTemplate(newCode) {
      if (
          this.mail_template.is_linked &&
          (!newCode.includes("{SLINK}") || !newCode.includes("<!--BUTTON-->"))
      ) {
        this.errorMessages.push(
            this.$t("validation.required_field").replace(":NAME", "{SLINK}")
        );
        return false;
      }
      this.mail_template.html = newCode;
      if (this.assignment_item.mail_template != undefined && newCode) this.assignment_item.mail_template = newCode;
    },
    codeSlice() {
      if (this.mail_template_codes.length == undefined) return 0;
      return Math.ceil(this.mail_template_codes.length / 2);
    },
    copyCode(code) {
      const el = document.createElement("textarea");
      el.value = code;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    addContentToAssignment(item) {
      let edu_id = item.id;
      if (this.all_selected_educations.includes(edu_id)) return false;
      this.hideSomeInputsForClassRoomEducation()
      this.educations.push(item);
      this.all_selected_educations.push(edu_id);
    },
    addGiftToContent(item) {
      let gift_id = item.id;
      this.all_selected_gifts.push(gift_id);
      this.gifts.push(item);
    },
    removeGiftFromContent(id) {
      let gifts = this.gifts;
      let gifts_ids_all = this.all_selected_gifts;
      let pos = gifts
          .map(function (x) {
            return x;
          })
          .indexOf(id);
      let pos_all = gifts_ids_all
          .map(function (x) {
            return x;
          })
          .indexOf(id);
      gifts.splice(pos, 1);
      gifts_ids_all.splice(pos_all, 1);
      return true;
    },
    removeContentFromAssignment(edu_id) {
      let educations = this.educations;
      let educations_ids_all = this.all_selected_educations;

      let pos_all = educations_ids_all
          .map(x => x)
          .indexOf(edu_id);

      let edu_pos = educations
          .map(x => x.id)
          .indexOf(edu_id);

      educations.splice(edu_pos, 1);
      educations_ids_all.splice(pos_all, 1);

      this.hideSomeInputsForClassRoomEducation()

      return true;
    },
    getAssignmentItem() {
      this.$store.dispatch(ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_GET_ITEM, {
        url: ASSIGNMENT_BASE_URL + "/" + this.$route.params.id,
      }).then((response) => {
        response.contents_programs?.forEach((data) => {
          this.contentsTimed.push({
            id: data?.id,
            type: data?.pivot?.timed_content_type ?? 'untimed_content',
            starts_at: data?.pivot?.starts_at,
            ends_at: data?.pivot?.ends_at
          });
        })
        this.setMinMaxTime();
      });
    },
    getSurveys() {
      this.$store.dispatch(SURVEY_MODULE_NAME + "/" + SURVEY_GET_ITEMS, {
        url: SURVEY_BASE_URL,
        filters: {
          status_id: 1,
          order: "asc",
          sort: "id",
          fields: "id,name",
        },
      });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getResultsAll() {
      this.getMailTemplateData();
      this.getAssignmentItem();
      this.getSurveys();
    },
    processFile(event) {
      this.cover_image = event.target.files[0];
      let blob = URL.createObjectURL(this.cover_image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#cover_photo_input").click();
    },
    isValidFields(fields) {
      let self = this;
      self.resetMessages()
      if (fields != 'ALL') fields--

      if (fields == "ALL" || fields == 0) {
        if (self.name == null || self.name.length == 0) {
          return self.showFieldsFailedError(0, self.$t("validation.required_field").replace(":NAME", "{{this.$t('general.assignment_name')}}"));
        }
        if (self.description == null || self.description.length == 0) {
          return self.showFieldsFailedError(0, self.$t("validation.required_field").replace(":NAME", "Açıklama"));
        }
      }
      if ((fields == "ALL" || fields == 1) && self.all_selected_educations.length == 0) {
        return self.showFieldsFailedError(1, self.$t("validation.required_field").replace(":NAME", "{{this.$t('messages.content')}}"));
      }
      if ((fields == "ALL" || fields == 2) && self.checked_users.length == 0 && self.checked_groups.length == 0) {
        return self.showFieldsFailedError(2, self.$t("validation.required_field").replace(":NAME", "Kullanıcılar"));
      }
      if ((fields == "ALL" || fields == 3) && self.assignment_item.has_present && self.all_selected_gifts.length == 0) {
        return self.showFieldsFailedError(3, self.$t("validation.required_field").replace(":NAME", "Hediye"));
      }
      if (fields == "ALL" || fields == 4) {
        if (self.start_date == null) {
          return self.showFieldsFailedError(4, self.$t("validation.required_field").replace(":NAME", "Başlangıç Tarihi"));
        }

        if (self.end_date == null) {
          return self.showFieldsFailedError(4, self.$t("validation.required_field").replace(":NAME", "Son Tamamlama Tarihi"));
        }

        // if (self.repeatable && self.repetition_no <= 0) {
        //   return self.showFieldsFailedError(4, self.$t("validation.required_field").replace(":NAME", "Tekrar Sayısı"));
        // }

        if (self.survey_required)
          if (self.survey_id == null || !self.survey_id) {
            return self.showFieldsFailedError(4, self.$t("validation.required_field").replace(":NAME", "Anketi"));
          }

        if (self.has_customized_template)
          if (self.mail_template.html == null || self.mail_template.html == '') {
            return self.showFieldsFailedError(4, self
                .$t("validation.required_field")
                .replace(":NAME", "E-posta Şablonunu"));
          }
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(
          message
      );
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {
      if (this.isValidFields(index)) {
        this.switcherChange(index);
      }

      const settingsTabIndexNumber = 4;
      if (index === settingsTabIndexNumber) {
        this.hideSomeInputsForClassRoomEducation();
      }
      // for qr code
      if (index === 5) {
        this.decideQrCodeForShowing();
      }
    },
    switcherChange(index) {
      UIkit.switcher($("#main-switcher")[0]).show(index);
    },
    setMinimumDate(start_date = null) {
      let date = new Date();
      if (start_date) {
        let startDate = new Date(start_date);
        date = new Date(startDate.getTime() + 86400000); // + 1 day in ms
      }
      return date.toISOString().split("T")[0];
    },
  },
  mounted() {
    this.getResultsAll();
    setTimeout(() => {
      $(".selectpicker7").selectpicker();
    }, 200);

    this.qr_text = `${window.location.origin}/#/assignments/${this.$route.params.id}/classroom-education`;
  },
  watch: {
    description(val) {
      this.textarea_length = val.length
    },
    survey_items(value) {
      if (value && value.length)
        setTimeout(() => {
          $(".selectpicker_survey").selectpicker();
        }, 500);
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
    can_be_added_to_calendar(val) {
      val ? this.send_mail_notification = val : null;
      this.can_be_added_to_calendar = val;
    },
    send_mail_notification(val) {
      !val ? this.can_be_added_to_calendar = val : null;
      this.send_mail_notification = val;
    },
    send_mobile_notification(val) {
      this.send_mobile_notification = val;
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_learning");
    if (modal) modal.$destroy(true);

    const modalGift = UIkit.modal("#modal_gifts");
    if (modalGift) modalGift.$destroy(true);
    const modalCustomize = UIkit.modal(" #modal_customize");
    if (modalCustomize) modalCustomize.$destroy(true);

  }
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-tab li.uk-active a {
  border-color: #eceff0;
}

.uk-tab-right::before {
  display: none;
}

.sidemenu li a {
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}
</style>
